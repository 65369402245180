import React from "react"

import Page from "../../modules/NewLandingPage/components/Page"

const SitePrivacidade = () => {
  return (
    <Page>
      <div className="container privacidade">
        <div className="head-lp">
          <div className="pre-lp">levamos sua privacidade a sério</div>

          <h1>Política de privacidade</h1>
        </div>

        <p>
          Nós, do Anonimatta, inscrito no CNPJ/ME sob o n. 27.476.350/0001-85 e
          com sede na Cidade de Uberlândia, Estado de Minas Gerais, no Rua
          Marcelo Barbosa, nº 128 – Bairro Shopping Park, levamos a sua
          privacidade e a proteção dos seus dados a sério. Este documento
          explica como tratamos seus dados pessoais, quais são os seus direitos
          e como você pode exercê-los.
        </p>

        <p>
          <strong>Por que a Empresa trata seus dados pessoais?</strong>
        </p>

        <p>
          Para que você consiga acessar nossa plataforma com segurança e
          comodidade é necessário o tratamento de seus dados pessoais. Você pode
          ficar tranquilo, aqui nós tratamos a privacidade e a proteção de dados
          pessoais com transparência.
        </p>

        <p>
          <strong>
            1. Conceitos básicos: o que você precisa saber para compreender esta
            política?
          </strong>
        </p>

        <p>
          A fim de descomplicar a sua leitura deste documento, apresentamos
          algumas definições úteis para a sua interpretação. Estes termos serão
          utilizados em letra maiúscula:
        </p>

        <p>
          <strong>Dado Pessoal</strong>
          <br />É uma informação relacionada a uma pessoa física e que seja
          capaz de identificar a pessoa ou tornar possível a sua identificação.
        </p>

        <p>
          São exemplos de dados pessoais que podem permitir a sua identificação:
          Nome, CPF, Passaporte, telefone, e-mail, etc.
        </p>

        <p>
          <strong>Tratamento</strong>
          <br />É toda forma de uso que podemos fazer dos seus Dados Pessoais,
          incluindo, mas não se limitando às seguintes atividades: coleta,
          armazenamento, consulta, uso, compartilhamento, transmissão,
          classificação, reprodução, exclusão e avaliação.
        </p>

        <p>
          <strong>Titular</strong>
          <br />É você, a pessoa física a quem os Dados Pessoais se referem.
        </p>

        <p>
          <strong>Empresa</strong>
          <br />
          Somos nós, o Anonimatta.
        </p>

        <p>
          <strong>Política</strong>
          <br />É esta Política de Privacidade.
        </p>

        <p>
          <strong>2. QUE TIPOS DE DADOS PESSOAIS A EMPRESA TRATA?</strong>
        </p>

        <p>
          Os tipos de Dados Pessoais e a forma como a Empresa os coleta dependem
          de como você se relaciona com o Anonimatta e por quê. Por exemplo, os
          Dados Pessoais coletados serão diferentes caso você seja um assinante
          do nosso site ou um criador de conteúdo. Listamos abaixo algumas
          situações em que nós podemos tratar seus Dados Pessoais no contexto da
          sua relação com o Anonimatta:
        </p>

        <p>
          <strong>CADASTRO DO ASSINANTE</strong>
          <br />
          [NÚMERO DO TELEFONE (WHATSAPP) +<br />
          NOME COMPLETO + SENHA + CPF OU NÚMERO PASSAPORTE]
        </p>

        <p>
          <strong>CADASTRO DO CRIADOR DE CONTEÚDO</strong>
          <br />
          [NOME COMPLETO + CPF OU NÚMERO PASSAPORTE + TELEFONE (WHATSAPP) +
          SENHA + DADOS BANCÁRIOS (PARA RECEBER COMISSÕES]
        </p>

        <p>
          <strong>CUMPRIR OBRIGAÇÃO LEGAL</strong>
          <br />
          [ENDEREÇO DE IP + DATA E HORA DE ACESSO + PORTA LÓGICA DE CONEXÃO]
        </p>

        <p>
          <strong>MELHOR EXPERIÊNCIA</strong>
          <br />
          [INFORMAÇÕES SOBRE NAVEGAÇÃO E DADOS SOBRE O DISPOSITIVO MÓVEL]
        </p>

        <p>
          <strong>
            3. COM QUEM A EMPRESA COMPARTILHA OS SEUS DADOS PESSOAIS?
          </strong>
        </p>

        <p>
          Nós operamos em parceria com uma série de outras organizações para
          viabilizar as nossas atividades. Além disso, como prestamos um serviço
          pago, temos o dever de transmitir algumas informações a autoridades
          fiscais e governamentais, nos termos da lei. Descrevemos aqui algumas
          situações em que podemos compartilhar Dados Pessoais:
        </p>

        <p>
          <strong>Nossos Fornecedores e Parceiros.</strong>
        </p>

        <p>
          MOIP BY PAGSEGURO - CNPJ/MF 08.718.431/0001-08
          <br />
          GERENCIANET S.A. - CNPJ: 09.089.356/0001-18
          <br />
          ENOTAS - 14.422.279/0001-06
          <br />
          MUNICIPIO DE UBERLÂNDIA – CNPJ/MF 18.431.312/0001-15
          <br />
          RECEITA FEDERAL DO BRASIL– CNPJ/MF 00.394.460/0058-87
          <br />
          ESTADO DE MINAS GERAIS – CNPJ/MF 18.715.615/0001-60
          <br />
          GOOGLE BRASIL INTERNET LTDA – CNPJ/MF 06.990.590/0001-23
        </p>

        <p>
          <strong>
            4. QUAIS SÃO OS SEUS DIREITOS COMO TITULAR DE DADOS PESSOAIS?
          </strong>
        </p>

        <p>
          Os Dados Pessoais são seus e a lei brasileira lhe garante uma série de
          direitos relacionados a eles. Nós estamos comprometidos com o
          cumprimento desses direitos e, nessa seção, vamos explicar quais são
          esses direitos e como você pode exercê-los. Veja a seguir:{" "}
        </p>

        <p>
          <strong>Confirmação e Acesso</strong>
          <br />
          Você pode solicitar à Empresa a confirmação sobre a existência de
          tratamento dos seus Dados Pessoais para que, em caso positivo, você
          possa acessá-los, inclusive por meio de solicitação de cópias dos
          registros que temos sobre você.
        </p>

        <p>
          <strong>Correção</strong>
          <br />
          Você pode solicitar a correção dos seus Dados Pessoais caso estes
          estejam incompletos, inexatos ou desatualizados.
        </p>

        <p>
          <strong>Anonimização, bloqueio ou eliminação</strong>
          <br />
          Você pode solicitar (a) a anonimização dos seus Dados Pessoais, de
          forma que eles não possam mais ser relacionados a você e, portanto,
          deixem de ser Dados Pessoais; (b) o bloqueio dos seus Dados Pessoais,
          suspendendo temporariamente a sua possibilidade de os tratarmos para
          certas finalidades; e (c) a eliminação dos seus Dados Pessoais, caso
          em que deveremos apagar todos os seus Dados Pessoais sem possibilidade
          de reversão.
        </p>

        <p>
          <strong>Portabilidade</strong>
          <br />
          Você pode solicitar que a Empresa forneça os seus Dados Pessoais em
          formato estruturado e interoperável visando à sua transferência para
          um terceiro, desde que essa transferência não viole a propriedade
          intelectual ou segredo de negócios da Empresa.
        </p>

        <p>
          <strong>Informação sobre o compartilhamento</strong>
          <br />
          Você tem o direito de saber quais são as entidades públicas e privadas
          com as quais a Empresa realiza uso compartilhado dos seus Dados
          Pessoais. Manteremos, no item 3 dessa Política, uma indicação das
          nossas relações com terceiros que podem envolver o compartilhamento de
          Dados Pessoais. Em todo caso, se você tiver dúvidas ou quiser mais
          detalhes, você tem o direito de nos solicitar essas informações. A
          depender do caso, podemos limitar as informações fornecidas a você
          caso a sua divulgação possa violar a propriedade intelectual ou
          segredo de negócios da Empresa.
        </p>

        <p>
          <strong>Informação sobre a possibilidade de não consentir</strong>
          <br />
          Você tem o direito de receber informações claras e completas sobre a
          possibilidade e as consequências de não fornecer consentimento, quando
          ele for solicitado pela Empresa. O seu consentimento, quando
          necessário, deve ser livre e informado. Portanto, sempre que pedirmos
          seu consentimento, você será livre para negá-lo – nesses casos, é
          possível que alguns serviços não possam ser prestados.
        </p>

        <p>
          <strong>Revogação do consentimento</strong>
          <br />
          Caso você tenha consentido com alguma finalidade de tratamento dos
          seus Dados Pessoais, você pode sempre optar por retirar o seu
          consentimento. No entanto, isso não afetará a legalidade de qualquer
          Tratamento realizado anteriormente à revogação. Se você retirar o seu
          consentimento, é possível que fiquemos impossibilitados de lhe prestar
          certos serviços, mas iremos avisá-lo quando isso ocorrer.
        </p>

        <p>
          <strong>Oposição</strong>
        </p>

        <p>
          A lei autoriza o tratamento de Dados Pessoais mesmo sem o seu
          consentimento ou um contrato conosco. Nessas situações, somente
          trataremos seus Dados Pessoais se tivermos motivos legítimos para
          tanto, como, por exemplo, quando for necessário para garantir a
          segurança de nossas rodovias. Caso você não concorde com alguma
          finalidade de tratamento dos seus Dados Pessoais, você poderá
          apresentar oposição, solicitando a sua interrupção.
        </p>

        <p>
          <strong>AVISOS IMPORTANTES</strong>
          <br />
          Para sua segurança, sempre que você apresentar uma requisição para
          exercer seus direitos, o Anonimatta poderá solicitar algumas
          informações e/ou documentos complementares para que possamos comprovar
          a sua identidade, buscando impedir fraudes. Fazemos isso para garantir
          a segurança e a privacidade de todos.
        </p>

        <p>
          Em alguns casos, o Anonimatta pode ter motivos legítimos para deixar
          de atender a uma solicitação de exercício de direitos. Essas situações
          incluem, por exemplo, casos em que uma revelação de informações
          específicas poderia violar direitos de propriedade intelectual ou
          segredos de negócio do Anonimatta ou de terceiros, bem como casos em
          que pedidos de exclusão de dados não possam ser atendidos em razão da
          existência de obrigação do Anonimatta de reter dados, seja para
          cumprir obrigações legais, regulatórias ou para possibilitar a defesa
          do Anonimatta ou de terceiros em disputas de qualquer natureza.
          <br />
          Ainda, algumas solicitações podem não ser respondidas de forma
          imediata, mas o Anonimatta se compromete a responder todas as
          requisições em um prazo razoável e sempre em conformidade com a
          legislação aplicável.
        </p>

        <p>
          Caso você tenha alguma dúvida sobre essas questões ou sobre como você
          pode exercer seus direitos, fique à vontade para entrar em contato
          conosco por meio dos canais informados ao final desta Política.
        </p>

        <p>
          <strong>
            5. POR QUANTO TEMPO OS DADOS PESSOAIS SERÃO ARMAZENADOS?
          </strong>
        </p>

        <p>
          O Anonimatta possui uma política de retenção de Dados Pessoais
          alinhada com a lei aplicável. Dados Pessoais são armazenados somente
          pelo tempo que forem necessários para cumprir com as finalidades para
          as quais foram coletados, salvo se houver qualquer outra razão para
          sua manutenção como, por exemplo, cumprimento de quaisquer obrigações
          legais, regulatórias, contratuais, entre outras permitidas sob a lei.
        </p>

        <p>
          Sempre fazemos uma análise técnica para determinar o período de
          retenção adequado para cada tipo de Dado Pessoal coletado,
          considerando a sua natureza, necessidade de coleta e finalidade para a
          qual ele será tratado, bem como eventuais necessidades de retenção
          para o cumprimento de obrigações ou o resguardo de direitos.
        </p>

        <p>
          <strong>6. COMO O ANONIMATTA PROTEGE SEUS DADOS PESSOAIS?</strong>
        </p>

        <p>
          Nossa responsabilidade é cuidar dos seus Dados Pessoais e utilizá-los
          somente para as finalidades descritas nessa Política. Para garantir a
          sua privacidade e a proteção dos seus Dados Pessoais, adotamos
          recursos tecnológicos avançados para garantir a segurança de todos os
          dados tratados pelo Anonimatta. Entre as medidas de segurança
          implementadas estão a implementação de controles de acesso a sistemas
          e ambientes de tratamento de dados, técnicas de criptografia e a
          instalação de barreiras contra o acesso indevido às bases de dados
          (incluindo firewalls), entre outros controles de segurança da
          informação.
        </p>

        <p>
          Nós nos esforçamos para proteger a privacidade de seus Dados Pessoais,
          mas infelizmente não podemos garantir total segurança. Entradas e usos
          não autorizados de terceiros com informações suas, falhas de hardware
          ou software que não estejam sob controle do Anonimatta e outros
          fatores externos podem comprometer a segurança dos seus Dados
          Pessoais. Por isso, sua atuação é fundamental para a manutenção de um
          ambiente seguro para todos. Caso você identifique ou tome conhecimento
          de qualquer fator que comprometa a segurança dos seus dados na sua
          relação com a Empresa, por favor entre em contato conosco por meio das
          informações de contato indicadas abaixo.
        </p>

        <p>
          <strong>8. COMO FALAR SOBRE DADOS PESSOAIS COM O ANONIMATTA?</strong>
        </p>

        <p>
          Se você acredita que seus Dados Pessoais foram tratados de maneira
          incompatível com esta Política ou com as suas escolhas enquanto
          Titular dos seus Dados Pessoais, ou, ainda, se você tiver dúvidas,
          comentários ou sugestões relacionadas a esta Política e à forma como
          tratamos seus Dados Pessoais, entre em contato conosco. Nós temos um
          Encarregado que está à disposição nos seguintes endereços de contato:
        </p>

        <p>
          Endereço para correspondências: Rua Marcelo Barbosa, nº 128 – Bairro
          Shopping Park – Uberlândia/MG – CEP 38425-552
        </p>

        <p>Email para contato: legal@anonimatta.com.br</p>

        <p>
          <strong>9. MUDANÇAS NA POLÍTICA DE PRIVACIDADE</strong>
        </p>

        <p>
          Como estamos sempre buscando melhorar os nossos serviços e a forma
          como operamos, esta Política de Privacidade pode passar por
          atualizações para refletir as melhorias realizadas. Desta forma,
          recomendamos a visita periódica desta página para que você tenha
          conhecimento sobre as modificações efetivadas.
        </p>

        <p>
          <strong>DATA DA ÚLTIMA ATUALIZAÇÃO: 30/08/21.</strong>
        </p>

        <p>
          <strong>Histórico de versões:</strong>
          <br />
          Política de Privacidade 1.0
        </p>
      </div>
    </Page>
  )
}

export default SitePrivacidade
